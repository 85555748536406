<template>
  <div class="q-pa-md">
    <pro-deck :title="getRes('Form.Section.SiteVisitRequeset')" :cards="items" v-slot="{ card }">
      <pro-deck-card v-bind="card" />
    </pro-deck>
  </div>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";

/*eslint-disable*/
export default {
  components: { ProMenuLayout, ProDeckCard, ProDeck },
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Form.Field.CompanyOne",
          date: new Date(),
          actions: [
            { label: this.getRes("System.Button.View"), to: {} },
            { label: this.getRes("System.Button.Upload"), to: {} },
          ],
        },
        {
          id: 2,
          title: "Form.Field.CompanyTwo",
          date: new Date(),
          actions: [
            { label: this.getRes("System.Button.View"), to: {} },
            { label: this.getRes("System.Button.Upload"), to: {} },
          ],
        },
        {
          id: 3,
          title: "Form.Field.CompanyThree",
          date: new Date(),
          actions: [
            { label: this.getRes("System.Button.View"), to: {} },
            { label: this.getRes("System.Button.Upload"), to: {} },
          ],
        },
      ],
      statuses: [
        {
          key: "notConfirmedYet",
          label: this.getRes("Document.Status.NotConfirmedYet"),
          color: "orange",
        },
        {
          key: "completed",
          label: this.getRes("Document.Status.Completed"),
          color: "green",
        },
      ],
    };
  },
};
</script>
